//
// Scripts
//

import 'bootstrap/js/dist/collapse.js';
import 'bootstrap/js/dist/dropdown.js';
import ScrollSpy from 'bootstrap/js/dist/scrollspy.js';
import Toast     from 'bootstrap/js/dist/toast.js';


// Activate Bootstrap scrollspy on the main nav element
const sideNav = document.body.querySelector('#sidenav');
if (sideNav) {
    new ScrollSpy(document.body, {
        target: '#sidenav',
        offset: 74,
    });
};

// Collapse responsive navbar when toggler is visible
const navbar = document.body.querySelector('#navbarResponsive');
const navbarToggler = document.body.querySelector('.navbar-toggler');

const navBrand = [].slice.call(
    document.querySelectorAll('.navbar-brand')
);
const responsiveNavItems = [].slice.call(
    document.querySelectorAll('#navbarResponsive .nav-link')
);
[...navBrand, ...responsiveNavItems].map(function (responsiveNavItem) {
    responsiveNavItem.addEventListener('click', () => {
        if (window.getComputedStyle(navbarToggler).display !== 'none' && navbar.classList.contains('show')) {
            navbarToggler.click();
        }
    });
});

// Hide Hash Fragment when scrolling to the top
// document.addEventListener('scroll', () => {
//     if (window.scrollY === 0) {
//         window.history.replaceState(null, null, ' ');
//     }
// });

// Show E-mail Toast
const mailToast = document.body.querySelector('#about #mail-toast .toast');
const bsMailToast = new Toast(mailToast);

function copyMail() {
    navigator.clipboard.writeText(atob('bWFpbHRvOk1hSWwuU2hSRXlBZ0BHbUFpTC5jT20=')
        .toLowerCase()
        .substring(7)
    );
    mailToast.children[0].textContent = 'E-mail Address Copied!';
    bsMailToast.show();
}

document.getElementById('copy-mail').addEventListener('click', copyMail);
